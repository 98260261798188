import React, { useCallback, useEffect, useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { utcToZonedTime } from "date-fns-tz";

import { useTranslation, I18nextProvider } from "react-i18next";
import instance from "../../utils/i18n";
import { TFunction } from "react-i18next";

import "@esri/calcite-components/dist/components/calcite-label";
import "@esri/calcite-components/dist/components/calcite-button";
import "@esri/calcite-components/dist/components/calcite-icon";
import { CalciteButton, CalciteLabel } from "@esri/calcite-components-react";

import BooleanFilter from "./BooleanFilter/BooleanFilter";
import CategoryFilter from "./CategoryFilter/CategoryFilter";
import {
  AllFiltersContainer,
  StyledBadge,
  StyledFilter,
  StyledFilterBySection,
  StyledFilterColumnLayout,
  StyledFilterRowLayout,
  StyledFilterSection,
  StyledFilterTitle,
  StyledSectionHeading,
} from "./Filter-styled";
import {
  BooleanFilterItem,
  CategoryFilterValue,
  CategoryOption,
  FilterProps,
} from "./Filter.types";
import IsOpenFilter from "./IsOpenFilter/IsOpenFilter";
import { getTodaysFieldPairs } from "../LocationCard/OpenNowHelper";
import {
  _getCategoryFilterValues,
  _getCategoryFilterWhereClause,
  _getFieldNotNullCategoryFilterWhereClause,
  _sortItems,
} from "./CategoryFilter/CategoryFilterHelper";

const Filter = ({
  booleanFilters,
  categoryFilters,
  locationsFeatureLayer,
  openHoursServiceTypes,
  openHoursTimeWindows,
  onFilterUpdated,
  showOpenNowFilter,
  manyFilters,
  updateGoogleTranslate,
}: FilterProps) => {
  const [isFullscreenMode, setIsFullscreenMode] = useState<boolean>(false);
  const [categoryOptions, setCategoryOptions] = useState<CategoryOption[]>([]);
  const [isOpenNowWhere, setIsOpenNowWhere] = useState<string>();
  const [showClearFilterBtn, setShowClearFilterBtn] = useState<boolean>(false);
  const [showAriaLive, setShowAriaLive] = useState<boolean>(false);
  const [booleanFiltersActive, setBooleanFiltersActive] = useState<boolean[]>([]);
  const [booleanFiltersWhere, setBooleanFiltersWhere] = useState<string>();
  const [activeFilterCount, setActiveFilterCount] = useState<number>(0);
  const [filterLeftSideY, setFilterLeftSideY] = useState<number>(65);

  const { t, i18n } = useTranslation("components", { i18n: instance });

  /* Boolean filter */

  useEffect(() => {
    if (booleanFilters?.length) {
      setBooleanFiltersActive(booleanFilters.map((item) => false));
    }
  }, [booleanFilters]);

  useEffect(() => {
    const wheres: string[] = [];
    if (booleanFiltersActive?.length) {
      booleanFiltersActive.forEach((isActive, index) => {
        if (isActive) {
          const filter: BooleanFilterItem = booleanFilters[index];
          let where = `${filter.filter_field} = `;
          where +=
            typeof filter.true_value === "string" ? `'${filter.true_value}'` : filter.true_value;
          wheres.push(where);
        }
      });
      setBooleanFiltersWhere(wheres.join(" AND "));
    }
  }, [booleanFilters, booleanFiltersActive]);

  /* Category filter */

  const getCategoryOptions = useCallback(() => {
    const categoryFilterValues = _getCategoryFilterValues({
      filters: categoryFilters,
      layer: locationsFeatureLayer,
    });
    const updatedCategories = categoryFilters.map((filter) => {
      const options = categoryFilterValues
        ? categoryFilterValues.filter((x) => filter.filter_fields.includes(x.fieldName))
        : [];
      return {
        label: filter.filter_label,
        anyLabel: filter.filter_any_label,
        selectedLabel: filter.filter_selected_label,
        type: filter.filter_type,
        options: filter.persist_order ? options : _sortItems(options, "label"),
      } as CategoryOption;
    });
    console.log("updatedCategories", updatedCategories);

    setCategoryOptions(updatedCategories);
    updateGoogleTranslate();
  }, [categoryFilters, locationsFeatureLayer, updateGoogleTranslate]);

  const updateCategoryValueState = (
    targetOption: CategoryFilterValue,
    type: string,
    t: TFunction
  ) => {
    const copyCategoryOptions = JSON.parse(JSON.stringify(categoryOptions)) as CategoryOption[];
    const o = copyCategoryOptions
      .flatMap((filter) => {
        return filter.options;
      })
      .find(
        (option) =>
          option.fieldName === targetOption.fieldName && option.value === targetOption.value
      );
    if (o) {
      o.checked = !targetOption?.checked;
      if (type === "radio") {
        const allOtherRadioOptions = copyCategoryOptions
          .flatMap((filter) => {
            return filter.options;
          })
          .filter(
            (option) =>
              option.fieldName === targetOption.fieldName && option.value !== targetOption.value
          );
        allOtherRadioOptions.forEach((option) => (option.checked = false));
      }
      setCategoryOptions(copyCategoryOptions);
    } else {
      console.warn(
        t("filter.filterValueMismatch", {
          targetOption: JSON.stringify(targetOption),
          categoryOptions: categoryOptions,
        })
      );
    }
  };

  const updateAllCategoryValueStates = (targetLabel: string) => {
    const makeSelected = !!categoryOptions
      .filter((filter) => {
        return filter.label === targetLabel;
      })
      .flatMap((filter) => {
        return filter.options;
      })
      .find((o) => !o.checked);
    const copyCategoryOptions = JSON.parse(JSON.stringify(categoryOptions)) as CategoryOption[];
    copyCategoryOptions.map((filter) => {
      if (filter.label === targetLabel) {
        filter.options.forEach((option) => {
          option.checked = makeSelected;
        });
      }
      return filter;
    });
    setCategoryOptions(copyCategoryOptions);
  };

  const clearCategoryFilter = () => {
    categoryOptions
      .flatMap((filter) => {
        return filter.options;
      })
      .forEach((option) => (option.checked = false));
  };

  useEffect(() => {
    if (categoryFilters && !categoryOptions?.length && locationsFeatureLayer) {
      locationsFeatureLayer.when(() => {
        getCategoryOptions();
      });
    }
  }, [categoryFilters, categoryOptions, getCategoryOptions, locationsFeatureLayer]);

  /* Open Now Filter */

  const getIsOpenNowWhere = () => {
    const applicableFieldPairs = getTodaysFieldPairs(openHoursServiceTypes, openHoursTimeWindows);
    const now = utcToZonedTime(Date.now(), "America/New_York");
    const nowTime = now.toLocaleString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });

    const clauses = applicableFieldPairs.map((fieldPair) => {
      return `(CAST(${fieldPair[0]} AS TIME) <= CAST('${nowTime}' AS TIME) AND CAST(${fieldPair[1]} AS TIME) >= CAST('${nowTime}' AS TIME))`;
    });
    return `(${clauses.join(" OR ")})`;
  };

  const toggleOpenNowFilter = () => {
    const isEnabled = !!isOpenNowWhere;
    const newWhere = isEnabled ? undefined : getIsOpenNowWhere(); // get before updating flag because it's slow sometimes
    setIsOpenNowWhere(newWhere);
  };

  const clearOpenNowFilter = () => {
    setIsOpenNowWhere(undefined);
  };

  const clearBooleanFilters = () => {
    if (booleanFilters?.length) {
      setBooleanFiltersActive(booleanFilters.map((item) => false));
    }
  };

  /* Show All */

  const onToggleShowAll = (show: boolean) => {
    setIsFullscreenMode(show);
    updateGoogleTranslate();
  };

  const filterLeftSideRef = useRef<HTMLInputElement>(null);

  /* General filter */

  const onClearFilters = () => {
    setShowAriaLive(true);
    clearOpenNowFilter();
    clearBooleanFilters();
    clearCategoryFilter();
    onFilterUpdated?.("");
    // give time for alert to be read
    setTimeout(() => {
      setShowAriaLive(false);
    }, 2000);
  };

  useEffect(() => {
    const selected = categoryOptions
      .filter((option) => option.type !== "notnull")
      .flatMap((filter) => {
        return filter.options;
      })
      .filter((option) => option.checked);

    const newWhere = _getCategoryFilterWhereClause(selected);
    const selectedNotNull = categoryOptions
      .filter((option) => option.type === "notnull")
      .flatMap((filter) => {
        return filter.options;
      })
      .filter((option) => option.checked);
    const notNullWhere = _getFieldNotNullCategoryFilterWhereClause(selectedNotNull);
    const wheres = [isOpenNowWhere, newWhere, notNullWhere, booleanFiltersWhere];
    // filter undefined out and join with ANDs
    const combinedWhere =
      wheres
        .filter((where) => !!where)
        .map((where) => `(${where})`)
        .join(" AND ") || "";
    setShowClearFilterBtn(Boolean(combinedWhere));
    onFilterUpdated?.(combinedWhere);
    let filterCount = selected.length;
    if (booleanFiltersWhere) {
      filterCount += booleanFiltersWhere.split(" AND ").length;
    }
    if (isOpenNowWhere) {
      filterCount++;
    }
    setActiveFilterCount(filterCount);
  }, [booleanFiltersWhere, categoryOptions, isOpenNowWhere, onFilterUpdated]);

  // Start observing the element when the component is mounted
  useEffect(() => {
    const element = filterLeftSideRef?.current;

    if (!element) return;

    const observer = new ResizeObserver(() => {
      const rect = element?.getBoundingClientRect();
      if (rect) {
        console.log(rect.y);
        setFilterLeftSideY(rect.y);
      }
      // 👉 Do something when the element is resized
    });

    observer.observe(element);
    return () => {
      // Cleanup the observer by unobserving all elements
      observer.disconnect();
    };
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <StyledFilter
        as={isFullscreenMode ? StyledFilterColumnLayout : StyledFilterRowLayout}
        manyFilters={manyFilters}
      >
        <div id="filter-left-side" ref={filterLeftSideRef}>
          {/* Show All, Title, X */}
          <StyledFilterSection>
            <AllFiltersContainer id="filter-show-all-container">
              <CalciteButton
                class="lib-neutral-button filter-spacing-offset"
                color="neutral"
                appearance="outline"
                icon-start="sliders-horizontal"
                onClick={() => {
                  onToggleShowAll(true);
                }}
                label={t("filter.showAll")}
                data-testid="showAllBtn"
              ></CalciteButton>
              <StyledBadge
                hidden={!showClearFilterBtn}
                top={filterLeftSideY}
                manyFilters={manyFilters}
              >
                {activeFilterCount}
              </StyledBadge>
            </AllFiltersContainer>
            <StyledFilterTitle className="filter-condensed">{t("filter.filter")}</StyledFilterTitle>
            <CalciteButton
              id="filter-x-btn"
              className="filter-condensed"
              onClick={() => {
                onToggleShowAll(false);
              }}
              appearance="transparent"
              color="neutral"
              iconStart="x"
              scale="s"
              label={t("filter.close")}
              data-testid="closeShowAllBtn"
            ></CalciteButton>
          </StyledFilterSection>
          {categoryOptions.map((category, index) => (
            <StyledFilterBySection
              id={"cat-filter-section-" + index.toString()}
              key={"cat-filter-section-" + index.toString()}
            >
              <StyledSectionHeading>{category.label}</StyledSectionHeading>
              <CalciteLabel
                layout="inline"
                class="filter-padding-offset filter-spacing-offset"
                for={`category-filter-button-${category.anyLabel.replace(/ /g, "_")}`}
              >
                <span className="filter-btn-label">{category.label}:</span>
              </CalciteLabel>
              <CategoryFilter
                isFullscreen={isFullscreenMode}
                allOptions={category.options}
                type={category.type}
                onToggleOption={(opt) => updateCategoryValueState(opt, category.type, t)}
                onToggleAll={() => updateAllCategoryValueStates(category.label)}
                anyLabel={category.anyLabel}
                selectedLabel={category.selectedLabel}
              />
            </StyledFilterBySection>
          ))}
          {showOpenNowFilter && (
            <StyledFilterBySection>
              <StyledSectionHeading>{t("filter.businessHours")}</StyledSectionHeading>
              <CalciteLabel layout="inline" class="filter-padding-offset filter-spacing-offset">
                <span className="filter-btn-label">{t("filter.businessHours")}:</span>
                <IsOpenFilter
                  filterLabel={t("filter.openNow")}
                  onClick={toggleOpenNowFilter}
                  isApplied={!!isOpenNowWhere}
                />
              </CalciteLabel>
            </StyledFilterBySection>
          )}
          {booleanFilters.map((item, index) => (
            <StyledFilterBySection
              id={"bool-filter-section-" + index.toString()}
              key={"bool-filter-section-" + index.toString()}
            >
              <StyledSectionHeading>{item.filter_label}</StyledSectionHeading>
              <CalciteLabel layout="inline" class="filter-padding-offset filter-spacing-offset">
                <span className="filter-btn-label">{item.filter_label}:</span>
                <BooleanFilter
                  booleanFilterItem={item}
                  onClick={() => {
                    setBooleanFiltersActive(
                      booleanFiltersActive.map((value, idx) => (idx === index ? !value : value))
                    );
                  }}
                  isApplied={
                    booleanFiltersActive?.length > index ? booleanFiltersActive[index] : false
                  }
                />
              </CalciteLabel>
            </StyledFilterBySection>
          ))}
        </div>
        <StyledFilterSection>
          <CalciteButton
            id="filter-apply-btn"
            class="lib-neutral-button filter-condensed"
            color="neutral"
            appearance="outline"
            onClick={() => {
              onToggleShowAll(false);
            }}
          >
            {t("filter.close")}
          </CalciteButton>

          <CalciteButton
            id="filter-clear-btn"
            class="lib-neutral-button"
            color="neutral"
            appearance="outline"
            onClick={onClearFilters}
            label={t("filter.clear")}
            style={showClearFilterBtn ? undefined : { visibility: "hidden" }}
          >
            <span className="no-wrap">{t("filter.clear")}</span>
          </CalciteButton>
          <div id="filters-cleared-alert" aria-live="assertive">
            {showAriaLive && <span>{t("filter.cleared")}</span>}
          </div>
        </StyledFilterSection>
      </StyledFilter>
    </I18nextProvider>
  );
};

export default Filter;
