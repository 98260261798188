import React from "react";
import { createRoot } from "react-dom/client";
import ReactGA from "react-ga4";
import "@fontsource/public-sans";
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { AppContextProvider } from "./contexts/AppContext";
import esriRequest from "@arcgis/core/request";
import "./utils/i18n";
import { AppConfig } from "./contexts/AppConfig.types";
import { setAssetPath } from "nyc-component-lib";
import { setAssetPath as setComponentsAssetPath } from "@esri/calcite-components/dist/components";

const basename = process.env.PUBLIC_URL === "" ? "./" : `${process.env.PUBLIC_URL}/`;
const relUrl = new URL(basename, document.baseURI).href;

console.log("PUBLIC_URL", relUrl);
setAssetPath(relUrl);
setComponentsAssetPath(relUrl);

//setAssetPath("https://unpkg.com/@esri/calcite-components/dist/calcite/assets");
//setComponentsAssetPath("https://unpkg.com/@esri/calcite-components/dist/calcite/assets");

let configSrc = `${process.env.PUBLIC_URL}/config/runtime-config.json`;
let app: string;
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  // app = "foodhelp";
  // app = "childcareproviders";
  // app = "coolingcenters";
  // app = "hurricane";
  app = "victimservices";
  // app = "hopeservices";
  configSrc = `${process.env.PUBLIC_URL}/config/${app}-runtime-config.json`;
}

const fetchRuntimeConfig = async () => {
  try {
    // Fetch runtime configurations and add to the global namespace
    const response = await esriRequest(configSrc);
    let data = response.data;

    const appConfigEdit = window.sessionStorage.getItem("appConfigEdit");
    if (appConfigEdit) {
      // override from session (allows editing of config in the browser)
      // copy appConfig in session storage to appConfigEdit and make changes
      data = JSON.parse(appConfigEdit);
      console.log("CONFIG:", "appConfigEdit");
    } else {
      window.sessionStorage.setItem("appConfig", JSON.stringify(data));
      console.log("CONFIG:", configSrc);
    }

    window.runtimeConfig = data;
    window.appConfig = data.appConfig as AppConfig;
    console.log("APPCONFIG:", window.appConfig);

    const rootElement = document.documentElement;
    if (window.appConfig?.styling) {
      rootElement.style.setProperty("--app-primary-hue", window.appConfig.styling.hue);
      rootElement.style.setProperty(
        "--app-primary-saturation",
        window.appConfig.styling.saturation
      );
      rootElement.style.setProperty("--app-primary-lightness", window.appConfig.styling.lightness);
    }

    // get the active mapconfig that matches the hostname
    let hostName = window.location.hostname;
    if (hostName.toLowerCase().includes("-pr")) {
      hostName = "localhost"; //default pr sites to localhost
    }

    const environments = data.appEnvironments[hostName];
    if (environments) {
      const env = environments.find((e: { org: string }) => e.org === data.activeOrg);
      if (env) {
        console.log("ENVIRONMENT:", env);
        window.activeMapConfig = env;
        if (env.ga_id) {
          ReactGA.initialize(env.ga_id);
          ReactGA.send("pageview");
        } else {
          console.log("Google Analytics ID Not Defined in Runtime Config.");
        }
      } else {
        throw new Error(`Map Configuration Active Org Not Found: ${data.activeOrg}`);
      }
    } else {
      throw new Error(`Map Configuration Environment Not Found For: ${hostName}`);
    }
    return;
  } catch (error) {
    console.error("Error loading runtime configurations from config/runtime-config.json", error);
  }
};

const getIsAppActive = async () => {
  try {
    const deactivate_test = window.activeMapConfig?.deactivate_test;
    if (!deactivate_test) {
      return true;
    }
    const { request, field_name, deactive_value } = deactivate_test;
    const results = await esriRequest(request.url, request.options);
    const recent_value = results.data.features[0].attributes[field_name];

    // return value is NOT equal to deactive state
    console.log(`Deactivate value = ${deactive_value} the current value is ${recent_value}`);
    return deactive_value !== recent_value;
  } catch (error) {
    console.error("Error performing active/deactive test, setting app to active.", error);
    return true;
  }
};

const init = async () => {
  // write the build id to the console
  console.log("BUILD: 2.2.114");

  await fetchRuntimeConfig();
  const isActive = await getIsAppActive();

  const rootElement = document.getElementById("root");
  const root = createRoot(rootElement as Element);

  // Fix vh on mobile
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  window.addEventListener("resize", () => {
    const newvh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${newvh}px`);
  });

  root.render(
    //<React.StrictMode>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <AppContextProvider isAppActive={isActive}>
        <App />
      </AppContextProvider>
    </BrowserRouter>
    // </React.StrictMode>
  );
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// initializes the site
init();
