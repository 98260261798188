/*
    AppTheme provides global styles to all components:
    - FontAwesome Solid icons
    - CSS variables for consistent styles
        - App-level Configurable Primary Color
        - Library-level default colors, font sizes, spacing
        - Library-level redefined Calcite Light Theme variables
    - Google Translate overrides
    - Helper classes with additional Calcite restyles
    - CSS Reset
*/

import { createGlobalStyle } from "styled-components";
import { Breakpoints } from "./Breakpoints";

//Add all FA Solid icons to "icon library"
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/pro-solid-svg-icons";
library.add(fas);

const AppTheme = createGlobalStyle`
    :root {
    /*------ Palette ------*/

        /*--- Configurable Colors (currently using defaults) ---*/
            --app-primary-hue: 207;
            --app-primary-saturation: 88%;
            --app-primary-lightness: 31%;
            --app-primary-lightness-darker: 25%;

        /*--- Derived from Configurable Colors ---*/
            --app-primary-color: hsl(var(--app-primary-hue), var(--app-primary-saturation), var(--app-primary-lightness));
            --app-primary-hover: hsl(var(--app-primary-hue), var(--app-primary-saturation), var(--app-primary-lightness-darker));
            --app-primary-press: hsl(var(--app-primary-hue), var(--app-primary-saturation), var(--app-primary-lightness));

        /*--- Accent colors ---*/
            /* Default primary color */
            --lib-primary-blue: #0a5796;
            --lib-primary-blue-hover: hsl(207,88%,25%);
            --lib-primary-blue-press: hsl(207,88%,20%);
            /* Link color */
            --lib-secondary-blue: #1771b7;
            --lib-secondary-blue-hover: hsl(206,78%,34%);
            --lib-secondary-blue-press: hsl(206,78%,29%);
            /* Secondary & success color */
            --lib-primary-green: #288369; 
            --lib-primary-green-hover: hsl(163, 53%, 28%);
            --lib-primary-green-press: hsl(163, 53%, 23%);
            /* Error color */
            --lib-primary-red: #dd363a;
            --lib-primary-red-hover: hsl(359, 71%, 48%);
            --lib-primary-red-press: hsl(359, 71%, 43%);
            /* Warning color */
            --lib-primary-orange: #ca4f07;
            --lib-primary-orange-hover: #f17131;
            --lib-primary-orange-press: #e95911;

        /*--- Neutrals ---*/
            --lib-primary-gray: #333333;    // Headings color
            --lib-secondary-gray: #666;     // Text color
            --lib-background: #efefef;
            --lib-foreground: #fff;
            --lib-foreground-hover: #f9f9f9;

        /*--- Transparency ---*/
            --app-landing-cover-darkness: 0.2;
            --lib-shadow: rgb(0 0 0 /25%);    // Used in all box shadows
        --lib-shadow-dark: rgb(0 0 0 /50%); // Modal

        /*--- Calcite Light Theme variables ---*/
            --calcite-ui-brand: var(--app-primary-color);
            --calcite-ui-brand-hover: var(--app-primary-hover);
            --calcite-ui-brand-press: var(--app-primary-press);
            --calcite-ui-text-1: var(--lib-primary-gray);
            --calcite-ui-text-2: var(--lib-secondary-gray);
            --calcite-ui-background: var(--lib-background);
            --calcite-ui-foreground-1: var(--lib-foreground);
            --calcite-ui-info: var(--lib-primary-blue);
            --calcite-ui-warning: var(--lib-primary-orange);
            --calcite-ui-danger: var(--lib-primary-red);
            --calcite-ui-danger-hover: var(--lib-primary-red-hover);
            --calcite-ui-danger-press: var(--lib-primary-red-press);
            --calcite-ui-success: var(--lib-primary-green);

    /*------ Typography ------*/

        --lib-text-size-0: 1.0rem;
        --lib-text-size-1: 1.1rem;
        --lib-text-size-2: 1.2rem;
        --lib-text-size-3: 1.4rem;
        --lib-text-size-4: 1.8rem;
        --lib-text-size-5: 2.6rem;

        @media ${Breakpoints.mobile} {
            --lib-text-size-0: 0.9rem;
            --lib-text-size-1: 1.0rem;
            --lib-text-size-2: 1.1rem;
            --lib-text-size-3: 1.2rem;
            --lib-text-size-4: 1.4rem;
            --lib-text-size-5: 1.8rem;
        }

    /*------ Layout constraints ------*/

        --lib-default-spacing: 1rem; 
        --lib-min-card-width: 20rem;
        --lib-max-card-width: 32rem;

        @media ${Breakpoints.mobile} {
            --lib-default-spacing: 0.75rem;
        }
    }

    /*------ Screenreader utils ------*/

    .visually-hidden:not(:focus):not(:active) {
        border: 0;
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        white-space: nowrap;
        width: 1px;
   }

    /*------ Direction variables ------*/

    div[dir="rtl"]{
        --rtl-rotate: 180deg; //rotates chevron icon on location cards
        --rtl-text-align: right; //aligns text right in details modal
    }

    /*------ Google Translate overrides ------*/

        //Pretty sure this is causing the map/list view button overflow issue - Lillie
        #goog-gt-tt {
            display: none !important;
        }

        #google_translate_element {
            display: none;
        }

    /*------ Helper classes to override default Calcite styles ------*/

        .lib-neutral-button {
            --calcite-ui-text-1: var(--app-primary-color) !important;
            --calcite-ui-foreground-3: var(--lib-shadow); //border color
            --calcite-ui-border-input: var(--lib-shadow); //border color for combobox
        }

        .lib-secondary-button {
            --calcite-ui-brand: var(--lib-primary-green);
            --calcite-ui-brand-hover: var(--lib-primary-green-hover);
            --calcite-ui-brand-press: var(--lib-primary-green-press);
        }

        #error-alert {
            --calcite-ui-foreground-1:var(--lib-primary-red);
            --calcite-ui-foreground-2:var(--lib-primary-red-hover); //button
            --calcite-ui-foreground-3:var(--lib-primary-red-press); //button
            --calcite-ui-text-3: #fff !important; //idle icon
            --calcite-ui-text-2: #fff !important; //alert text
            --calcite-ui-text-1: #fff !important; //hover icon
        }

        .info-link {
            text-decoration: underline;
            color: var(--app-primary-color);
        }

        /*
            This hack removes padding from the content container of the calcite-modal
            while retaining padding in the header container. Both use the variable
            --calcite-modal-padding, however #header uses --calcite-modal-padding-internal
            as a fallback. Can't set --c-m-p to 0px as that won't trigger the fallback.
        */

        #details-modal, #phone-modal {
            --calcite-scrim-background: rgba(0, 0, 0, 0.5) !important; //modal wrapper color, doesn't work
            --calcite-modal-padding: var(--break-this-on-purpose) !important; //must break this to activate fallback below
            --calcite-modal-padding-large: 0px !important; //extra padding to left of header elements
            --calcite-modal-padding-internal: 1rem !important;
            --calcite-modal-content-padding: 0px; // was preventing modal content from spanning full width in mobile
        }

        #x-btn {
            align-self: start;
        }

        #translate-combobox {
            --calcite-ui-icon-color: var(--lib-secondary-gray);
        }

        .calcite-label-padding-offset {
            margin-bottom: -12px;
        }

        .label-nowrap {
            white-space: nowrap;
        }    
        
        div[data-layer-title="Hurricane Evacuation Zone"]:not(.esri-popup--is-docked-bottom-center) .esri-popup__main-container { 
            width: 100px;
        }

    /*------ CSS Reset ------*/

        // enables correct screen height on mobile devices
        html,
        body,
        #root {
            height: inherit;
            width: 100vw;
        }

        body {
            font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            height: 100vh;
            height: calc(var(--vh, 1vh) * 100); //--vh set in app index.tsx
        }

        body, h1, h2, h3, h4, p, a, ul, li, th, td {
            margin: 0;
            padding: 0;
            border: 0;

            //prevent unnecessary tracking on Safari, render sharp text
            font-feature-settings: "kern";
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            text-rendering:optimizeLegibility !important;
            font-weight: normal;
        }

        h1 {
            color: var(--app-primary-color);
        }

        h2, h3, h4 {
            color: var(--lib-primary-gray);
        }

        p {
            color: var(--lib-secondary-gray);
        }

        a {
            color: var(--lib-secondary-blue);
            text-decoration: underline;
            cursor: pointer;
        }

        a:hover {
            color: var(--lib-secondary-blue-hover);
        }

        a:active {
            color: var(--lib-secondary-blue-press);
        }


        [inert] {
            pointer-events: none;
            cursor: default;
        }

        [inert],
        [inert] * {
            user-select: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
        }
`;

export default AppTheme;
